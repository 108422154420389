@import 'abstracts/variables';
@import 'abstracts/helpers';
@import 'ProductCard.module';

// Base category card styles that can be shared
.category-card-base {
  .product-card;

  .body {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    padding: 0;
  }

  .category-name {
    .multiline-ellipsis();

    width: 158px; // To have a consistent width of all cards, magic number to match pixel perfect design
    flex-shrink: 1;
    flex-grow: 0;
    text-align: center;
    color: @headings-color;
    word-break: break-word;
    hyphens: auto;
  }

  .image-container {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;
    min-height: 0;
  }
}

// Specific styles for the regular category card
.category-card {
  .category-card-base;

  height: 120px; // To match figma design

  .category-name {
    width: 158px; // To have a consistent width of all cards, magic number to match pixel perfect design
    line-height: 16px; // To match with figma
    margin-top: @spacer;
  }

  .image-container-height {
    // This defines the max-height of the actual image <Image />
    max-height: 72px;
    margin: 0 auto; // Center horisontally
  }

  :global {
    .rs-panel {
      padding: 7px @spacer * 1.5 @spacer; // Magic number to match figma design
    }
  }
}
