@import '../abstracts/variables';
@import '../abstracts/helpers';
@import '../CategoryCard.module';

.category-card {
  .category-card-base;
  @card-dimension: 190px;

  height: @card-dimension;
  min-width: @card-dimension;
  border-radius: @border-radius-base;
  background: @white;

  .category-name {
    margin-top: @spacer * 2;
    font-size: @font-size-base + 2;
    font-weight: 700;
    font-family: @headings-font-family;
  }

  :global {
    .rs-panel {
      padding: @spacer * 2.5;
      box-shadow: 0 0 10px rgb(0 0 0 / 6%), 0 4px 4px rgb(194 194 194 / 12%);
    }
  }
}
